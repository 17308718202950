const config = {
  "broker": "1",
  "name": "Korean",
  "registrationBundle": "korean",
  "defaultCountryName": "Republic of Korea",
  "defaultCountryCode": "KR",
  "fxgeoip_code": "KR",
  languageCode: 'ko',
  languageName:'Korean',
  spokenLanguageCode:'ko',
  direction:'ltr',
  footerDisclaimer:'737',
  brand: 'iforex'
};
module.exports = config;